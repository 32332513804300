<template>
	<el-dialog
			top="0" class="commonDia updateMem" :close-on-click-modal="false"
			:visible.sync="dialogRole" :show-close="false"
			width="700px" :close-on-press-escape="false">
		<template #title>
			<h1 class="tit">{{ statusText }}角色</h1>
		</template>
		<el-form label-position="top" ref="form" :rules="rules" class="rowsInfo" label-suffix="：" label-width="80px"
						 :model="roleForm">
			<div class="flex">
				<el-form-item label="角色名称" prop="roleName">
					<el-input v-model="roleForm.roleName" clearable placeholder="请输入角色名称"></el-input>
				</el-form-item>
				<el-form-item label="角色代码">
					<el-input v-model="roleForm.roleCode" clearable placeholder="请输入角色代码"></el-input>
				</el-form-item>
			</div>
			<el-form-item label="电脑端角色权限">
				<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
				</el-checkbox>
				<el-checkbox-group v-model="roleForm.permissions" class="checkBoxGroup"
													 style="max-height: 80px;overflow-y: scroll;">
					<el-checkbox :label="item.id" :key="ind" v-for="(item,ind) in permissionList">{{ item.descrip }}</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="手机端角色权限">
				<el-checkbox-group v-model="roleForm.permissions" class="checkBoxGroup"
													 style="max-height: 80px;overflow-y: scroll">
					<el-checkbox :label="item.id" :key="ind" v-for="(item,ind) in permissionListPhone">{{ item.descrip }}
					</el-checkbox>
				</el-checkbox-group>
			</el-form-item>

			<el-form-item label="角色描述">
				<el-input type="textarea" rows="4" v-model="roleForm.remark" clearable placeholder="请输入角色描述"></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="resetForm">取 消</el-button>
			<el-button type="primary" @click="roleButton('form')">确 定</el-button>
		</div>
	</el-dialog>

</template>

<script>
import { addAuthRole, updateAuthRole } from "@/api/api";

export default {
	name: "roleEdit",
	props: {
		showEditRole: {
			type: Boolean,
			default: false
		},
		state: {
			type: String
		},
		roleInfo: {
			type: Object
		},
		permissionList: {
			type: Array
		},
		permissionListPhone: {
			type: Array
		}
	},
	data() {
		return {
			dialogRole: this.showEditRole,
			statusText: this.state,
			roleForm: {},
			isIndeterminate: true,
			checkAll: false,
			rules: {
				roleName: [
					{ required: true, message: "请输入用户名", trigger: "blur" }
				]
			}
		};
	},
	watch: {
		showEditRole: {
			handler(newval) {
				this.dialogRole = newval;
			}
		},
		state: {
			handler(newval) {
				this.statusText = newval;
			}
		},
		roleInfo: {
			handler(val) {
				if (val) {
					this.roleForm = JSON.parse(JSON.stringify(this.roleInfo));
					if (this.roleInfo.permissionIds != "" && this.roleInfo.permissionIds != null) {
						let split = this.roleInfo.permissionIds.split(",");
						this.roleForm.permissions.push(...split);
					}
				} else {
					this.roleForm = {
						roleName: "",
						remark: "",
						permissions: []
					};
				}
			},
			deep: true
		}
	},
	methods: {
		handleCheckAllChange(val) {
			let ids = [];
			this.permissionList.forEach(i => {
				ids.push(i.id);
			});
			this.roleForm.permissions = val ? ids : [];
			this.isIndeterminate = false;
		},
		resetForm() {
			this.$emit("openRole", { "flg": false });
		},
		roleButton(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.statusText === "新增") {
						addAuthRole(JSON.stringify(this.roleForm)).then(res => {
							if (res.data.code == 200) {
								this.$message.success("新增成功");
							} else {
								this.$message.error(res.data.msg);
							}
						});
					} else {
						updateAuthRole(JSON.stringify(this.roleForm)).then(() => {
							this.$message.success("修改成功");
						}).catch(() => {
							this.$message.error("修改失败");
						});
					}
					setTimeout(() => {
						this.$emit("openRole", { "flg": false });
					}, 500);
				} else {
					return false;
				}
			});
		}

	}
};
</script>


<style lang="scss" scoped>
@import "../../assets/css/table";

.flex {
	align-items: normal;
}
</style>
